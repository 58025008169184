import AccountSettingsPersonal from "./components/AccountSettingsPersonal";
import { MediumText } from "../../../components/typography";
import { Alert, Button, Container, Grid } from "@mui/material";
import Header from "components/header/Header";
import AccountSettingsBilling from "./components/AccountSettingsBilling";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { patchUserData, selectInformationMissing, selectUserRole, updateInformationMissing } from "features/user/userSlice";
import { clearCustomerErrors, patchCustomerSettings } from "features/customer/customerSlice";
import { useEffect } from "react";
import { Form, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/store";
import { setFromShoppingCart } from "features/shoppingCart/shoppingCartSlice";



const AccountSettings = () => {
  const navigate = useNavigate()

  const userRole = useAppSelector(selectUserRole);
  const informationMissing = useAppSelector(selectInformationMissing);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(clearCustomerErrors())

    // dispatch(setFromShoppingCart(true))
  }, [])


  const location = useLocation()
  // console.log(location.state)
  const fromShoppingCart = useSelector((state: RootState) => state.shoppingCart.fromShoppingCart) as boolean;

  // console.log(fromShoppingCart)
  return (
    <Container
      maxWidth="xl"
      sx={{
        pt: 4,
        pb: 4,
        mb: 3,
        // ml: 4,
        mt: 5,
      }}
    >
      <Form>
        {/* {informationMissing && (
          document.forms[0]?.elements && Object.values(document.forms[0].elements).some((element: any) => element.value === "") ? (
            <Alert
              severity="warning"
              sx={{
                borderRadius: 5,
                paddingX: 5,
                marginTop: -4,
                marginBottom: 3
              }}
            >
              Sie müssen die Informationen ausfüllen!
            </Alert>
          ) : null
        )} */}
        {!informationMissing && (
          document.forms[0]?.elements && Object.values(document.forms[0].elements).some((element: any) => element.value !== "") ? (
            <Alert
              severity="success"
              sx={{
                borderRadius: 5,
                paddingX: 5,
                marginTop: -4,
                marginBottom: 3
              }}
            >
              Die Änderungen wurden erfolgreich gespeichert.
            </Alert>
          ) : null
        )}
        {informationMissing ? (
          <Alert
            severity="warning"
            sx={{
              borderRadius: 5,
              paddingX: 5,
              marginTop: -4,
              marginBottom: 3
            }}
          >
            Bitte füllen Sie die fehlenden Informationen aus!
          </Alert>
        ) : null
        }

        <Grid container>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Header header={" Ihr Benutzerprofil"} />
          </Grid>
          <Grid item xs={12} md={6} >
            <AccountSettingsPersonal />
          </Grid>
          {userRole === "customer" ?
            <Grid item xs={12} md={6} sx={{ marginTop: { xs: 4, md: 0 } }}>
              <AccountSettingsBilling />
            </Grid> : null
          }
        </Grid>
        <div>
          <br />
          <MediumText>* Pflichtfeld</MediumText>
          <Button
            type="submit"
            variant="contained"
            onClick={() => {

              dispatch(updateInformationMissing(false));
              const isFormValid = document.forms[0].checkValidity(); // Check form validity

              if (isFormValid) {
                dispatch(patchUserData());
                if (userRole === "customer") {
                  dispatch(patchCustomerSettings());
                }

              } else {
                dispatch(updateInformationMissing(true));
              }

              if (fromShoppingCart) {
                dispatch(setFromShoppingCart(false));
                navigate('/bestelluebersicht')
              }

            }}
            sx={{
              color: "#072D26",
              my: 4,
              backgroundColor: "#C4D661",
              borderColor: "#C4D661",
              borderRadius: 6,
              fontSize: "small",
              paddingY: 1.5,
              paddingX: 5,

              "&:hover": {
                backgroundColor: "#C0D904",
              },
            }}
          >

            <MediumText color="#072D26" fWeight="bold">
              Änderungen Speichern
            </MediumText>
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default AccountSettings;

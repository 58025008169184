import { Edit } from "@mui/icons-material";
import { Box, Container, IconButton, InputAdornment, Paper, SvgIcon, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks"
import Header from "components/header/Header";
import SearchBar from "components/inputs/HolzfinderSearchBar";
import HolzfinderList from "components/list/HolzfinderList";
import { getSellersList, selectSellersList, selectSellersListTotal } from "features/sellersList/sellersListSlice"
import { selectPageSeller, selectRowsPerPageSeller, setPageSellerList, setRowsPerPageSellerList } from "features/table/tableSlice";
import { selectUserRole } from "features/user/userSlice";
import useDate from "hooks/useDate";
import { DeleteSmall, Search } from "icons";
import { number } from "prop-types";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";


interface rowInterface {
  id: string;
  company: string;
  street: string;
  street_number: string;
  zip_code: string;
  city: string;
  eldat_reference_id: number;
  county_eldat_reference_id?: number
}
const tableHeaderListAdmin: string[] = ["Kreisnr.", "Betriebsnr.", "Betriebsname", "Straße", "Hausnummer", "Postleitzahl", "Stadt", "Bearbeiten"]
const tableHeaderListAgent: string[] = ["Betriebsnr.", "Betriebsname", "Straße", "Hausnummer", "Postleitzahl", "Stadt", "Bearbeiten"]

export default function SellersList() {
  const [rowData, setRowData] = useState<rowInterface[]>([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const sellersList = useAppSelector(selectSellersList);
  const sellersListTotal = useAppSelector(selectSellersListTotal);
  const page = useAppSelector(selectPageSeller)
  const rowsPerPage = useAppSelector(selectRowsPerPageSeller)
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(selectUserRole)
  const [pageCache, setPageCache] = React.useState(page);
  const [rowsPerPageCache, setRowsPerPageCache] = React.useState(rowsPerPage);

  const receiveSellersList = (resetSearch: boolean) => {
    if (resetSearch) setSearchQuery("")
    dispatch(
      getSellersList({
        items_per_page: rowsPerPage,
        page: page + 1,
        search: resetSearch ? undefined : searchQuery,
      })
    );
  };

  useEffect(() => {
    receiveSellersList(false)
  }, [page, rowsPerPage, searchQuery]);
  useEffect(() => {

    if (searchQuery) {
      dispatch(setPageSellerList(0));
    }
  }, [searchQuery]);

  useEffect(() => {
    if (userRole === "admin") {
      const mappedData = sellersList.map(
        (seller) => ({
          id: seller.id,
          county_eldat_reference_id: seller.agent.county_eldat_reference_id,
          eldat_reference_id: seller.eldat_reference_id,
          company: seller.company ? seller.company : `${seller.first_name} ${seller.last_name}`,
          street: seller.street,
          street_number: seller.street_number,
          zip_code: seller.zip_code,
          city: seller.city,
        })
      );
      setRowData(mappedData);
    } else {
      const mappedData = sellersList.map(
        (seller) => ({
          id: seller.id,
          eldat_reference_id: seller.eldat_reference_id,
          company: seller.company ? seller.company : `${seller.first_name} ${seller.last_name}`,
          street: seller.street,
          street_number: seller.street_number,
          zip_code: seller.zip_code,
          city: seller.city,
        })
      );
      setRowData(mappedData);
    }
  }, [sellersList])
  useEffect(() => {
    dispatch(setPageSellerList(0));
  }, [searchQuery]);

  return (
    <Container sx={{ pl: { lg: 12, md: 12, xs: 12 }, pt: 5 }} maxWidth="xl">
      <Header header="Verkäuferliste" />

      <SearchBar page={page} rowsPerPage={rowsPerPage} getListType={getSellersList} setSearchQuery={setSearchQuery} />

      <HolzfinderList
        listLengthTotal={sellersListTotal}
        rowData={rowData}
        switchActive={false}
        link={`/${userRole}/verkaeuferliste/`}
        tableHeaderList={userRole === "admin" ? tableHeaderListAdmin : tableHeaderListAgent}
        setPage={setPageSellerList}
        setRowsPerPage={setRowsPerPageSellerList}
        page={page}
        rowsPerPage={rowsPerPage === undefined ? 10 : rowsPerPage}
        isLotList={false}
      />
    </Container>
  )
}
import { Button, Paper, Box } from "@mui/material";
import { useAppSelector } from "app/hooks";
import { MediumText } from "components/typography";
import { selectShoppingCartLots, selectShoppingCartSize, setFromShoppingCart } from "features/shoppingCart/shoppingCartSlice";
import { selectUserIsLoggedId } from "features/user/userSlice";
import { useNavigate } from "react-router-dom";

function sumOfShoppingCart(shoppingCartData: any) {

  let value = 0;
  for (const element of shoppingCartData) {
    // console.log(element.price_total)
    value += parseFloat(element.price_total)
  }
  return value
}
interface Props {
  text: string;
  fromShoppingCart: boolean;
}
export default function ShoppingSummary(props: Props) {
  const numberFormatter = new Intl.NumberFormat("de-De", { style: "currency", currency: "EUR" });
  const { text, fromShoppingCart } = props
  const shoppingCartData = useAppSelector(selectShoppingCartLots)
  const shoppingCartSize = useAppSelector(selectShoppingCartSize)
  const shoppingCartValue = sumOfShoppingCart(shoppingCartData)
  const navigate = useNavigate();
  const isLoggedIn = useAppSelector(selectUserIsLoggedId)
  function dispatch(arg0: { payload: boolean; type: "shoppingCart/setFromShoppingCart"; }) {
    throw new Error("Function not implemented.");
  }

  return (
    <>
      {shoppingCartData.length > 0 ? (
        <Paper
          sx={{
            borderRadius: 3,
            height: "fit-content",
            mt: 2.2
          }}
        >
          <Box sx={{ padding: 3, display: "flex", flexDirection: "column" }}>
            <MediumText fWeight="bold" whiteSpace="pre">
              Ihre Zwischensumme
            </MediumText>
            <Box>
              <MediumText>
                ({shoppingCartSize} Artikel): {numberFormatter.format(Number(shoppingCartValue))}
              </MediumText>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
            <Button
              disabled={!isLoggedIn}
              onClick={() => {
                navigate(`/bestellung_pruefen`, {
                  state: {
                    fromShoppingCart: fromShoppingCart
                  }

                });

              }}
              sx={{ padding: "12px 48px", borderRadius: 6, backgroundColor: !isLoggedIn ? "lightgrey" : "#D9E868", "&:hover": { backgroundColor: "#D9E868" } }}
            >
              <MediumText color="#072D26" fWeight="bold" whiteSpace="pre">
                {text}
              </MediumText>
            </Button>
          </Box>
        </Paper>
      ) : (
        <></>
      )}
    </>
  );
}
import { Box, Checkbox, FormControlLabel, IconButton, Paper, SvgIcon, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { MediumText } from "components/typography";
import { setAgentId } from "features/agent/agentSlice";
import { Edit } from "icons";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./LosListe.module.css";
import { getLotList } from "features/lotlist/lotListSlice";
import { selectLotListTab } from "features/table/tableSlice";
import { CheckBox } from "@mui/icons-material";
import HolzfinderCheckbox from "components/inputs/HolzfinderCheckbox";
import HolzfinderLotListCheckbox from "components/inputs/HolzfinderLotListCheckbox";
interface Props {
  listLengthTotal: number;
  tableHeaderList: string[];
  sortableHeaders?: string[];
  headersBackEndName?: string[];
  rowData: any;
  switchActive: boolean;
  link?: string;
  tab?: number;
  switchStatus?: Function | null;
  setPage: Function;
  setRowsPerPage: Function;
  page: number;
  rowsPerPage: number;
  setAgent?: boolean;
  handleSortChange?: Function;
  isLotList: boolean;
  setCheckedBoxes?: Function;
  checkedBoxes?: string[]
}

export default function HolzfinderList(props: Props) {
  const { listLengthTotal, tableHeaderList, rowData, switchActive, link, tab, switchStatus, setPage, setRowsPerPage, page, rowsPerPage, setAgent = false, handleSortChange, sortableHeaders, headersBackEndName, isLotList, checkedBoxes, setCheckedBoxes } = props
  const dispatch = useAppDispatch();
  const [checkedPages, setCheckedPages] = useState(Array)
  const [checkedTabs, setCheckedTabs] = useState(Array)


  // const [orderDirection, setOrderDirection] = useState("asc");
  // const [currentSort, setCurrentSort] = useState("");
  const tabInfo = [
    { name: "active", color: "#c0d904" },
    { name: "inactive", color: "#F4DFDC" },
    { name: "sold", color: "#CED3CB" },
    { name: "planned", color: "#ACD9E5" },
  ]
  const getSwitchColor = function getSwitchColor(status: string) {
    var color = undefined
    tabInfo.forEach(function (value) {
      if (status === value.name) {
        color = value.color
      }
    })

    return color || "transparent"
  }
  const handleCheckboxChange = (id: string) => {
    if (isLotList && checkedBoxes && setCheckedBoxes) {
      const isAlreadyChecked = checkedBoxes.includes(id)
      var checkedBoxesUpdateCopy = [...checkedBoxes];
      if (isAlreadyChecked) {
        const index = checkedBoxesUpdateCopy.indexOf(id)
        checkedBoxesUpdateCopy.splice(index, 1)
      } else {
        checkedBoxesUpdateCopy.push(id)
      }
      setCheckedBoxes(checkedBoxesUpdateCopy)
    }
  }
  const handleCheckboxChangeHeader = (event: any) => {
    if (isLotList && checkedBoxes && setCheckedBoxes) {
      var checkedBoxesUpdateRef = [...checkedBoxes]
      var checkedPagesUpdateRef = checkedPages
      var checkedTabsUpdateRef = checkedTabs
      if (checkedPagesUpdateRef.includes(page) && checkedTabsUpdateRef.includes(tab)) {
        rowData.map((row: any) => {
          if (checkedBoxesUpdateRef.includes(row.id)) {
            const index = checkedBoxesUpdateRef.indexOf(row.id)
            checkedBoxesUpdateRef.splice(index, 1)
          }
        })
        const indexPage = checkedPagesUpdateRef.indexOf(page)
        checkedPagesUpdateRef.splice(indexPage, 1)
        const indexTab = checkedTabsUpdateRef.indexOf(tab)
        checkedTabsUpdateRef.splice(indexTab, 1)
      } else {
        rowData.map((row: any) => {
          if (!(checkedBoxesUpdateRef.includes(row.id)) && row.status != "sold") {
            checkedBoxesUpdateRef.push(row.id)
          }
        })
        checkedPagesUpdateRef.push(page)
        checkedTabsUpdateRef.push(tab)
      }
      setCheckedPages(checkedPagesUpdateRef)
      setCheckedTabs(checkedTabsUpdateRef)
      setCheckedBoxes(checkedBoxesUpdateRef)
    }
  }


  const resetCheckboxes = () => {
    setCheckedPages([]);
    setCheckedTabs([]);

  };

  useEffect(() => {
    resetCheckboxes();
  }, [tab]);
  // useEffect(() => {
  //   changeSort()
  // }, [currentSort, orderDirection])

  // const changeSort = () => {
  //   const index = sortableHeaders ? sortableHeaders.indexOf(currentSort) : undefined
  //   const orderBy = headersBackEndName && index != undefined ? headersBackEndName[index] : undefined
  //   const order = orderDirection === "asc" ? "" : "-"
  //   const sort = orderBy ? order.concat(orderBy) : undefined
  //   handleSortChange?.(sort)
  // }

  // const changeOrder = (column: string) => {
  //   if(currentSort === column){
  //     setOrderDirection(orderDirection === "asc" ? "desc" : "asc")
  //   }else{
  //     setCurrentSort(column)
  //   }
  // }


  return (
    <div>
      {listLengthTotal > 0 ? (
        <Box sx={{ alignSelf: "start", maxHeight: "100%", overflow: "hidden" }}>
          <Box
            sx={{
              overflowY: "auto",
              overflowX: "hidden",
              height: "100%",
              boxSizing: "content-box",
              width: "auto",
            }}
          >
            <TableContainer
              component={Paper}
              sx={{
                border: "1px solid #D0C2A8",
                borderRadius: "0px !important",
                width: "auto",
              }}
            >
              <Table
                sx={{
                  minWidth: 650,
                  color: "#D0C2A8",
                  width: "100%",
                }}
                aria-label="simple table"
              >
                <TableHead sx={{ width: "auto" }}>
                  <TableRow>
                    {isLotList ? (
                      <TableCell>
                        <HolzfinderLotListCheckbox checked={checkedPages.includes(page) && checkedTabs.includes(tab)} handleCheckBoxChange={handleCheckboxChangeHeader} disabled={false} isHeaderCheckbox={true} />
                      </TableCell>
                    ) : null}
                    {tableHeaderList.map((e, key) => (
                      <TableCell key={key} className={styles.tableHeader}>
                        <TableSortLabel hideSortIcon={true}>
                          {e}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowData.map((row: any, key: any) => (
                    <TableRow key={key}>
                      {isLotList && checkedBoxes ? (
                        <TableCell key={key + row.id}>
                          <HolzfinderLotListCheckbox checked={checkedBoxes.includes(row.id)} handleCheckBoxChange={() => handleCheckboxChange(row.id)} disabled={row.status === "sold"} isHeaderCheckbox={false} />
                        </TableCell>
                      ) : null}
                      {row.src ? (
                        <TableCell sx={{ height: 50, width: 50 }} key={key}>
                          <Box
                            key={key}
                            component="img"
                            src={row.src}
                            alt=""
                            sx={{
                              width: 50,
                              aspectRatio: "16/9",
                              objectFit: "cover",
                            }} />
                        </TableCell>
                      ) : null}
                      {Object.entries(row).filter((row): any => {
                        if (row[0] !== "src" && row[0] !== "status" && row[0] !== "checked" && row[0] !== "id") {
                          return row;
                        }
                      }).map(([key, value]: [string, any]) => (

                        <TableCell key={key}>{value}</TableCell>
                      ))}
                      {switchActive ? (
                        <TableCell>
                          <Switch
                            sx={{
                              width: "50px",
                              "span .MuiSwitch-thumb": {
                                backgroundColor: getSwitchColor(row.status)
                              },
                              ".MuiSwitch-track": {
                                backgroundColor: getSwitchColor(row.status),
                                borderRadius: "20px",
                                opacity: tab === 4 ? "0.8" : "1 !important",
                                "&:after, &:before": {
                                  color: "black",
                                  fontSize: "11px",
                                  position: "absolute",
                                },
                                "&:before": {
                                  content: "'On'",
                                  pr: 1,
                                },
                                "&:after": {
                                  right: 2,
                                  content: "'Off'",
                                },
                              },
                            }}
                            disabled={row.status === "sold"}
                            checked={row.checked}
                            onChange={(event) => {
                              if (switchStatus) {
                                switchStatus(row.id, event.target.checked);
                              }
                            }} />
                        </TableCell>
                      ) : null}
                      <TableCell onClick={() => {
                        if (setAgent) dispatch(setAgentId(row.id));
                      }}>
                        <Link to={link && link.length > 0 ? link + `${row.id}` : `${row.id}`}>
                          <IconButton>
                            <SvgIcon>
                              <Edit />
                            </SvgIcon>
                          </IconButton>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          </Box>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50]}
            labelRowsPerPage="Reihen pro Seite:"
            labelDisplayedRows={({ from, to, count }) => {
              return "" + from + "-" + to + " von " + count;
            }}
            component="div"
            count={listLengthTotal}
            page={page}
            onPageChange={(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
              dispatch(setPage(newPage))
            }}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              dispatch(setRowsPerPage(+event.target.value));
              dispatch(setPage(0));
            }}
          />
        </Box>
      ) : (
        <Box sx={{
          width: "fit-content",
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          boxShadow: "7px 5px 5px 0px rgba(0,0,0,0.6)",
          backgroundColor: "#D3D3D3",
          padding: 1,
          mt: 1
        }}>
          <MediumText>
            Es gibt keinen Treffer zu ihrer Suche.
          </MediumText>
        </Box>
      )}
    </div>
  )
}

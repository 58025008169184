import { Backdrop, Button, Modal } from "@mui/material";
import { Container } from "@mui/system";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { AxiosResponseHeaders } from "axios";
import Header from "components/header/Header";
import QuantityRestriction from "components/modals/QuantityRestriciton";
import { MediumText } from "components/typography";
import { customerSettings, selectCustomer } from "features/customer/customerSlice";
import { fetchValidateResponse } from "features/shoppingCart/fetchValidate";
import { checkShoppingCart, clearShoppingCart, deleteFromShoppingIds, deleteItemFromShoppingCart, purchaseShoppingCart, selectShoppingCartIds, selectShoppingCartLots } from "features/shoppingCart/shoppingCartSlice";
import { selectUserData, selectUserIsLoggedId, updateInformationMissing } from "features/user/userSlice";
import { LotOfferLocationInterface } from "interfaces/LotOfferLocationInterface";
import React from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";


export default function CheckOrder() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [open, setOpen] = React.useState(false);
  const [navigateFurther, setNavigateFurther] = React.useState(false);
  const [informationText, setInformationText] = React.useState("");
  let lotInformation: LotOfferLocationInterface[] = []
  const { fromShoppingCart } = state
  const shoppingCartIds = useAppSelector(selectShoppingCartIds)
  const shoppingCart = useAppSelector(selectShoppingCartLots)
  const customerData = useAppSelector(selectCustomer);
  const userData = useAppSelector(selectUserData)
  let { city, street, street_number, zip_code, country_id } = customerData;
  let { first_name, last_name } = userData
  const isLoggedIn = useAppSelector(selectUserIsLoggedId)

  // useEffect(() => {
  //   dispatch(customerSettings())
  // }, [])

  useEffect(() => {
    console.log(customerData);
    // console.log(customerData);
    console.log(first_name, last_name, city, street, street_number, zip_code, country_id);
    if (!first_name || !last_name || !city || !street || !street_number || !zip_code || !country_id) {
      dispatch(updateInformationMissing(true));
      navigate("/kunde/mein_profil", { replace: true })
      setNavigateFurther(false);
    }
    setNavigateFurther(true)
  }, [customerData])


  useEffect(() => {
    if (!isLoggedIn) {
      // dispatch(clearShoppingCart());
      navigate("/login", { replace: true });
      setNavigateFurther(false);
    }
    // ... rest of your logic
  }, [isLoggedIn]);

  useEffect(() => {
    if (navigateFurther) {
      //test order for invalid lot id
      dispatch(checkShoppingCart(shoppingCartIds)).then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          const payload = response.payload as { headers: AxiosResponseHeaders; data: fetchValidateResponse };
          const overflownStatus = shoppingCartIds.some((id: string) => {
            if (payload.data.data?.overflown_lot_offer_ids?.includes(id)) {
              shoppingCart.map((lotData) => {
                if (lotData.id === id) {
                  lotInformation.push(lotData)
                  setInformationText(`Das Los ${lotData?.full_lot_number} kann nicht gekauft werden. ${lotData?.agent} erlaubt den Erwerb von ${lotData?.max_wood_per_period} Fm / ${lotData?.period_duration} Monate je registriertem Nutzer. Bitte entfernen Sie das Los aus Ihrem Warenkorb und versuchen Sie es erneut.`)
                }
              })
            }
            return payload.data.data?.overflown_lot_offer_ids?.includes(id)
          });

          const takenStatus = shoppingCartIds.some((id: string) => {
            if (payload.data.data?.taken_lot_offer_ids?.includes(id)) {
              shoppingCart.map((lotData) => {
                if (lotData.id === id) {
                  lotInformation.push(lotData)
                  setInformationText(`Das Los ${lotData?.full_lot_number} kann nicht gekauft werden. Es wurde leider schon verkauft. Bitte entfernen Sie das Los aus Ihrem Warenkorb und versuchen Sie es erneut.`)
                }
              })
            }
            return payload.data.data?.taken_lot_offer_ids?.includes(id)
          });

          if (overflownStatus) {
            handleOpen();
            return false
          }
          if (takenStatus) {
            handleOpen();
            return false
          }
          return true
        }

        return false
      }).then((result) => {
        if (result) {
          if (!fromShoppingCart && isLoggedIn) {
            dispatch(purchaseShoppingCart(shoppingCartIds)).then((response) => {
              navigate("/bestellung_erfolgreich", { replace: true });
              dispatch(clearShoppingCart());

            }).catch((err) => {
              // console.log('Errr....', err)
              console.error('Fehler beim Abrufen der Daten:', err);
            })
          } else {
            navigate("/bestelluebersicht", { replace: true });
          }
        }
      }).catch((err: any) => {
        // console.log(err)
      });
    }
  }, [navigateFurther, isLoggedIn])

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = (event: any) => {
    event.stopPropagation();
    setOpen(false);
    navigate("/einkaufswagen")
  };




  return (
    <Container>
      <Header header="Ihre Bestellung wird geprüft" />
      <Backdrop sx={{ backgroundColor: "#grey", zIndex: 1200 }} open={open} onClick={handleClose}>
        <Modal hideBackdrop open={open} onClose={handleClose} aria-labelledby="modal-title">
          <QuantityRestriction onClickEvent={() => {
            navigate("/einkaufswagen")
          }}
            checked={open}
            handleClose={handleClose}
            text={informationText}
          />
        </Modal>
      </Backdrop>
    </Container>
  )
}
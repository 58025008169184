// import logo from "./logo.svg";
// import { Counter } from "./features/counter/Counter";
import { HeaderNavbar } from "./components/header-navbar/HeaderNavbar";
import { FooterNavbar } from "./components/footer-navbar/FooterNavbar";

import { Outlet, useNavigate } from "react-router-dom";
import styles from "./App.module.css";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { logout, selectUserIsLoggedId } from "features/user/userSlice";
import { setupNavigator } from "app/axios";
import { useEffect } from "react";




function App() {
  const navigateTo = useNavigate();
  const isLoggedIn = useAppSelector(selectUserIsLoggedId);
  setupNavigator(navigateTo);
  const dispatch = useAppDispatch();

  const checkInternetConnection = () => {
    const isOnline = navigator.onLine;

    if (!isOnline) {
      dispatch(logout());
    }
  };

  useEffect(() => {
    checkInternetConnection();

    window.addEventListener('online', checkInternetConnection);
    window.addEventListener('offline', checkInternetConnection);

    return () => {
      window.removeEventListener('online', checkInternetConnection);
      window.removeEventListener('offline', checkInternetConnection);
    };
  }, [dispatch]);


  window.addEventListener('storage', e => {
    if (e.key === 'token' && e.oldValue && !e.newValue) {
      dispatch(logout());
      e.stopPropagation();
      e.preventDefault();
    }

  });

  return (
    <div className={styles.app}>
      <HeaderNavbar loggedIn={isLoggedIn} />
      <div className={styles.outlet} id="reactOutlet">
        <Outlet />
      </div>
      <FooterNavbar />
    </div>
  );
}

export default App;

import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { RequestStatus } from "interfaces/RequestStatus";
// import { UserData } from "interfaces/UserDataInterface";
import { getCustomerSettings, saveCustomerData } from "./fetchCustomerSettings";
import { CustomerDataInterface } from "interfaces/CustomerDataInterface";
import { getCustomerCountries } from "./fetchCustomerCountries";
import { CustomerCountryInterface } from "interfaces/CustomerCountriesInterface";
import { CustomerSettingsInterface } from "interfaces/CustomerSettingsInterface";
import {  fetchCustomer, fetchCustomerArgument, fetchCustomersList, fetchCustomersListArgument } from "./fetchCustomer"; //fetchAgentCustomer
import { fetchCustomerPurchase, fetchCustomerPurchaseArgument, fetchCustomerPurchasesList, fetchCustomerPurchasesListArgument } from "./fetchCustomerPurchases";
import { CustomerPurchaseInterface } from "interfaces/CustomerPurchaseInterface";

export interface LoginInformation {
  email: string;
  password: string;
}

interface CustomerErrors{
  string: string[]
}
interface CustomersListState {
  total: number;
  pages: number;
  itemsPerPage?: number;
  customers: CustomerSettingsInterface[];
}
interface CustomerPurchasesListState {
  total: number;
  pages: number;
  purchases: CustomerPurchaseInterface[];
}
export interface CustomerSettingsState {
  customer?: CustomerDataInterface;
  countries?: CustomerCountryInterface[];
  status: RequestStatus;
  errors?: CustomerErrors;
  customers?: CustomersListState;
  purchases?: CustomerPurchasesListState;
  purchase?: CustomerPurchaseInterface;
}

const initialCustomersListState: CustomersListState = {
  pages: 0,
  itemsPerPage: undefined,
  total: 0,
  customers: [],
};



const initialState: CustomerSettingsState = {
  status: RequestStatus.idle,
};

const emptyPurchase: CustomerPurchaseInterface = {
  user_id: "",
  customer_id: "",
  invoices: [],
  price_total: "",
  id: "",
  created_at: "",
  updated_at: ""
}

const emptyCustomer: CustomerDataInterface  = {
  id:"",
  country_id: "",
  company: "",
  street: "",
  street_number: "",
  zip_code: "",
  city: "",
  created_at: "",
  updated_at: "",
  first_name: "",
  last_name: "",
  email: "",
  mobile: "",
  phone: "",
  po_box: "",
  po_box_zip_code: "",
  fax: "",
  country_name: "",
  status: "",
}

const emptyCustomerErrors: CustomerErrors = {
  string: [""]
}

export const customerSettings = createAsyncThunk("customerSettings/data", async (args, {getState, rejectWithValue}) => {
  const state = getState() as RootState;
  if (state.user.role !== "customer") return emptyCustomer;
  
  const response = await getCustomerSettings();

  return response.data.data;
});

export const customerCountries = createAsyncThunk("customerCountries/data", async (args, {getState, rejectWithValue}) => {
  const state = getState() as RootState;
  if (state.user.role !== "customer" ) return { countries: [] };
  
  try{
    const response = await getCustomerCountries();
    return response.data.data;
  } catch(err: any){
    return rejectWithValue(err);
  }
});

export const getCustomers = createAsyncThunk("customer/fetchCustomers", async (args: fetchCustomersListArgument, { getState }) => {
  const { user } = getState() as RootState;
  const response = await fetchCustomersList({ ...args, endpoint: user.role });
  return { headers: response.headers, data: response.data };
});

export const getCustomer = createAsyncThunk("customer/fetchCustomer", async (args: fetchCustomerArgument, { getState }) => {
  const { user } = getState() as RootState;
  const response = await fetchCustomer({ ...args, endpoint: user.role });
  return { headers: response.headers, data: response.data };
});

// export const getAgentCustomer = createAsyncThunk("customer/fetchAgentCustomer", async (args: fetchCustomerArgument, { getState }) => {
//   const { user } = getState() as RootState;
//   const response = await fetchAgentCustomer({ ...args, endpoint: user.role });
//   console.log('3.5')
//   return { headers: response.headers, data: response.data };
// });




export const getCustomerPurchases = createAsyncThunk("customer/fetchCustomerPurchases", async (args: fetchCustomerPurchasesListArgument, { getState }) => {
  const { user } = getState() as RootState;
  const response = await fetchCustomerPurchasesList({ ...args });
  return { headers: response.headers, data: response.data };
});

export const getCustomerPurchase = createAsyncThunk("customer/fetchCustomerPurchase", async (args: fetchCustomerPurchaseArgument, { getState }) => {
  const { user } = getState() as RootState;
  const response = await fetchCustomerPurchase({ ...args });
  return { headers: response.headers, data: response.data };
});



// export const patchCustomerSettings = createAsyncThunk(
//   "customer/saveCustomerSettings",
//   async (newCustomerData: saveCustomerDataArgument) => {
//     const response = await saveCustomerData(newCustomerData);
//     return { data: response.data.data, header: response.headers };
//   }
// );
export const patchCustomerSettings = createAsyncThunk(
  "user/saveUserData",
  async (args, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    if (!state.customerSettings.customer) {
      return { data: {errors: {}} };
    }
    const response = await saveCustomerData({ customerData: state.customerSettings.customer });
    return { data: response.data.data };
  }
);


export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    logout: (state) => {
      return initialState;
    },
    clearCustomerErrors: (state) => {
      state.errors = emptyCustomerErrors;
    },
    clearCustomer: (state) => {
      state.customer = emptyCustomer;
    },
    clearPurchase: (state) => {
      state.purchase = emptyPurchase;
    },
    updateCompany: (state, action: PayloadAction<any>) => {
      if (state.customer)
      state.customer.company = action.payload
    },
    updateStreet: (state, action: PayloadAction<string>) => {
      if (state.customer)
      state.customer.street = action.payload;
    },
    updateStatus: (state, action: PayloadAction<string>) => {
      if (state.customer) {
        state.customer.status = action.payload;
      }
    },
    updateCustomerStatus: (state:any, action: PayloadAction<{ id: string; status: string }>) => {
      const { id, status } = action.payload;
      const customers = state.customers ?? [];
    
      for (let i = 0; i < customers.length; i++) {
        if (customers[i].id === id) {
          customers[i].status = status;
          break;
        }
      }
    },
    
    
    updateStreetNumber: (state, action: PayloadAction<string>) => {
      if (state.customer)
      state.customer.street_number = action.payload;
    },
    updateZipCode: (state, action: PayloadAction<string>) => {
      if (state.customer)
      state.customer.zip_code = action.payload;
    },
    updateCity: (state, action: PayloadAction<string>) => {
      if (state.customer)
      state.customer.city = action.payload;
    },
    updateCountryId: (state, action: PayloadAction<string>) => {
      if (state.customer)
      state.customer.country_id = action.payload;
    },
    updatePhone: (state, action: PayloadAction<string>) => {
      if (state.customer)
      state.customer.phone = action.payload;
    },
    // updateCountry: (state, action: PayloadAction<string>) => {
    //   if (state.customer)
    //   state.customer.country = action.payload;
    // },
    // updatePoBox: (state, action: PayloadAction<string>) => {
    //   state.customer.po_box = action.payload;
    // },
    // updatePhone: (state, action: PayloadAction<string>) => {
    //   state.customer.phone = action.payload;
    // },
    // updateMobile: (state, action: PayloadAction<string>) => {
    //   state.customer.mobile = action.payload;
    // },
    // updateFax: (state, action: PayloadAction<string>) => {
    //   state.customer.fax = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder
  //     .addCase(login.fulfilled, (state, action) => {
  //       state.status = RequestStatus.idle;
  //       state.customer_required = action.payload.data.customer_required;
  //       state.role = action.payload.data.role;
  //       getUserData()
  //     })
      .addCase(getCustomers.fulfilled, (state, action) => {
        state.status = RequestStatus.idle;
        state.customers = action.payload.data.data;
      })
      .addCase(getCustomer.fulfilled, (state, action) => {
        state.status = RequestStatus.idle;
        state.customer = action.payload.data.data;
      })
      .addCase(getCustomerPurchases.fulfilled, (state, action) => {
        state.status = RequestStatus.idle;
        state.purchases = action.payload.data.data;
      })
      .addCase(getCustomerPurchase.fulfilled, (state, action) => {
        state.status = RequestStatus.idle;
        // console.log(action.payload.data.data)
        state.purchase = action.payload.data.data;
      })
      .addCase(customerSettings.fulfilled, (state, action) => {
        state.customer = action.payload as CustomerDataInterface;
        state.errors = {} as CustomerErrors;
      })
      .addCase(customerCountries.fulfilled, (state, action) => {
        state.countries = action.payload.countries
                    .map(function(country: CustomerCountryInterface) {
                      const firstLetter = country.name[0]
                      return {
                        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                        label: country.name + " (" + country.country_code + ")",
                        ...country,
                      };
                    })
                    .sort((a, b) => -b.name[0].localeCompare(a.name[0]));
      })
      .addCase(patchCustomerSettings.fulfilled, (state, action) => {
        //TODO: Clear errors after succesfull request

        // console.log(action.payload.data)
        if (action.payload.data && action.payload.data.errors) {
          // console.log("errors")    
          
          state.errors = action.payload.data.errors as CustomerErrors;
        } else {    
          // console.log("no errors")    
          state.errors = {} as CustomerErrors;
        }
      })
  },
});

// , current_password ,password,password_confirmation
export const { logout, clearCustomer, clearPurchase, updateCompany, updateStreet, updateStreetNumber, updateZipCode, updateCity, updateCountryId, clearCustomerErrors, updatePhone, updateStatus,updateCustomerStatus  } = customerSlice.actions;
// export const { logout, updateCountryId, updateCompany, updateStreet, updateStreetNumber, updateZipCode, updateCity, updatePoBoxZipCode, updatePoBox, updatePhone, updateMobile, updateFax } = customerSlice.actions;

export const selectCustomerSettings = (state: RootState) => state.customerSettings;
export const selectCustomer = (state: RootState) =>  state.customerSettings.customer || emptyCustomer;
export const selectCountries = (state: RootState) => state.customerSettings.countries;
export const selectErrors = (state: RootState) => state.customerSettings.errors || {}
export const selectCustomersList = (state: RootState) => state.customerSettings.customers?.customers;
export const selectCustomersListTotal = (state: RootState) => state.customerSettings.customers?.total;
export const selectCustomerPurchasesList = (state: RootState) => state.customerSettings.purchases?.purchases;
export const selectCustomerPurchasesTotal = (state: RootState) => state.customerSettings.purchases?.total;
export const selectCustomerPurchase = (state: RootState) => state.customerSettings.purchase;
export const selectCustomerStatus = (state: RootState) => state.customerSettings.customer?.status;

export default customerSlice.reducer;





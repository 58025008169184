import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Container, IconButton, InputAdornment, Select, SvgIcon, Switch, TextField, Tooltip } from "@mui/material";
import styles from "./LosListe.module.css";
import TablePagination from "@mui/material/TablePagination";
import { Delete, DeleteSmall, Edit, Search } from "icons";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  selectLotList,
  getLotList,
  selectLotListTotal,
} from "features/lotlist/lotListSlice";
import { useEffect, useState } from "react";
import LotListTabs from "./LotListTabs";
import { Link } from "react-router-dom";
import axiosInstance from "app/axios";
import useDate from "hooks/useDate";
import Header from "components/header/Header";
import { selectUserRole } from "features/user/userSlice";
import { MediumText } from "components/typography";
import SearchBar from "components/inputs/HolzfinderSearchBar";
import HolzfinderList from "components/list/HolzfinderList";
import { selectLotListTab, selectPageLotList, selectRowsPerPageLotList, setLotListTab, setPageLotList, setRowsPerPageLotList } from "features/table/tableSlice";
import { useTheme } from '@mui/material/styles'; import { selectFilter, selectSorting, setFilter, setSorting } from "features/lotlist/filterAndSortSlice";
import { setSort } from "features/lotLocations/lotLocationsSlice";
import BulkEdit from "./BulkEditModal";
import BulkEditModal from "./BulkEditModal";

interface rowInterface {
  id: string;
  checked: boolean;
  lot_number: string;
  date: string;
  kind: string;
  wood_type: string;
  volume: string;
  length: string;
  diameter: string;
  price: string;
  // gross_price: string;
  net_price: string;
  status: string;
  src: string;
}

enum LOT_LIST_TABS {
  ALL,
  ACTIVE,
  INACTIVE,
  SOLD,
  PLANNED

}


const lotListTabNames = ['', 'active', 'inactive', 'sold', 'planned']

const tableHeaderListAdmin = ['Bild', 'Kreisnr.', 'Los-Nr.', 'Eingestellt seit', 'Sorte', 'Holzart', 'Volumen [FmoR]', 'Ø-Länge', 'Ø-Durchm.', '€ / fm (netto)', 'Preis netto', 'Aktiv', 'Bearbeiten']
const sortableHeaders = ['Kreisnr.', 'Los-Nr.', 'Eingestellt Seit', 'Holzart', 'Volumen [FmoR]', 'Ø-Länge', 'Ø-Durchm.', '€ / fm (netto)', 'Preis netto']
const headersBackEndName = ["district_number", "lot_number", "published_at", "wood_type", "solid_cubic_meters_without_bark", "average_length_value", "diameter", "price_per_solid_cubic_meter", "price_total"]

const tableHeaderListAgent = ['Bild', 'Los-Nr.', 'Eingestellt seit', 'Sorte', 'Holzart', 'Volumen [FmoR]', 'Ø-Länge', 'Ø-Durchm.', '€ / fm (netto)', 'Preis netto', 'Aktiv', 'Bearbeiten']



export default function LosListe() {
  const numberFormatter = new Intl.NumberFormat("de-De", { maximumSignificantDigits: 2 });
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchQuery, setSearchQuery] = useState()
  // const searchQuery = useAppSelector(selectFilter)
  const [rowData, setRowData] = useState<rowInterface[]>([]);
  const lotList = useAppSelector(selectLotList);
  const lotListTotal = useAppSelector(selectLotListTotal);
  const dispatch = useAppDispatch();
  const baseURL = process.env.REACT_APP_API_DOMAIN;
  const userRole = useAppSelector(selectUserRole);
  const { format } = useDate();
  const page = useAppSelector(selectPageLotList)
  const rowsPerPage = useAppSelector(selectRowsPerPageLotList)
  const tab = useAppSelector(selectLotListTab)
  const priceFormatter = new Intl.NumberFormat("de-De", { style: "currency", currency: "EUR" });
  const sort = useAppSelector(selectSorting)
  const [checkedBoxes, setCheckedBoxes] = useState([])

  const theme = useTheme();

  const receiveLotList = () => {

    dispatch(
      getLotList({
        items_per_page: rowsPerPage,
        page: page + 1,
        search: searchQuery,
        status: lotListTabNames[tab],
        sort: sort,
      })
    );
  };
  // const handleSortChange = (sorting: string) => {
  //   dispatch(setSorting(sorting))
  // }
  // const setSearchQuery = (filter: string) => {
  //   dispatch(setFilter(filter))
  // }
  useEffect(() => {
    setCheckedBoxes([]);
  }, [tab]);
  useEffect(() => {
    if (userRole === "admin") {
      const filteredAndMappedData = lotList
        .map((row) => ({
          id: row.id,
          //@ts-ignore
          src: Array.isArray(row.images) && row.images.length > 0 ? baseURL + row.images[0].url : " ",
          district: userRole === "admin" && row.agent ? row.agent.county_eldat_reference_id : null,
          lot_number: row.lot_number,
          date: row.published_at && row.status === "planned" ? "geplant für " + format(row.published_at) : row.published_at ? format(row.published_at) : "----",
          kind: row.wood_category.name,
          wood_type: row.lot_offers_wood_types.map((woodType) => woodType.wood_type.name).join(", "),
          volume: parseFloat(row.solid_cubic_meters_without_bark).toFixed(2).replace('.', ',') + " Fm",
          length: parseFloat(row.average_length_value).toFixed(2).replace('.', ',') + " m",
          diameter: parseInt(numberFormatter.format(Number.parseFloat(row.diameter))).toFixed(0) + " cm",
          price: priceFormatter.format(Number.parseFloat(row.price_per_solid_cubic_meter)),
          // gross_price: Number.parseFloat(row.price_total)
          //   .toFixed(2)
          //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "1") + "€",
          net_price: priceFormatter.format(Number.parseFloat(row.price_total)),
          status: row.status,
          checked: row.status === "active" ? true : false,
        }));
      setRowData(filteredAndMappedData);
    } else {
      const filteredAndMappedData = lotList

        .map((row) => ({
          id: row.id,
          //@ts-ignore
          src: Array.isArray(row.images) && row.images.length > 0 ? baseURL + row.images[0].url : " ",
          lot_number: row.lot_number,
          date: row.published_at && row.status === "planned" ? "geplant für " + format(row.published_at) : row.published_at ? format(row.published_at) : "----",
          kind: row.wood_category.name,
          wood_type: row.lot_offers_wood_types.map((woodType) => woodType.wood_type.name).join(","),
          volume: parseFloat(row.solid_cubic_meters_without_bark).toFixed(2).replace('.', ',') + " Fm",
          length: parseFloat(row.average_length_value).toFixed(2).replace('.', ',') + " m",
          diameter: parseInt(numberFormatter.format(Number.parseFloat(row.diameter))).toFixed(0) + " cm",
          price: priceFormatter.format(Number.parseFloat(row.price_per_solid_cubic_meter)),
          // gross_price: Number.parseFloat(row.price_total)
          //   .toFixed(2)
          //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "1") + "€",
          net_price: priceFormatter.format(Number.parseFloat(row.price_total)),
          status: row.status,
          checked: row.status === "active" ? true : false,
        }));
      setRowData(filteredAndMappedData);
    }
  }, [lotList]);

  useEffect(() => {
    receiveLotList()
  }, [tab, page, rowsPerPage, sort, searchQuery])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setPageLotList(0))
    dispatch(setLotListTab(newValue));
  };

  const switchStatus = (id: string, newChecked: boolean) => {
    const newItems = rowData.map((row) => {
      if (row.id === id) {
        const payload: any = {};
        payload["status"] = row.status === "active" ? "inactive" : "active";
        axiosInstance.patch(`${userRole}/lot_offers/${row.id}`, payload);

        return {
          ...row,
          checked: newChecked,
          status: row.status === "active" ? "inactive" : "active",
        };
      } else {
        return row;
      }
    });
    setRowData(newItems);
  };
  useEffect(() => {
    dispatch(setPageLotList(0));
  }, [searchQuery]);


  return (
    <Container sx={{
      pt: 5,
      pr: 0,
      pl: 0,
      [theme.breakpoints.down(1650)]: {
        width: '90%',

      },
    }} maxWidth="xl">
      <Header header="Losliste" />
      <SearchBar page={page} rowsPerPage={rowsPerPage} getListType={receiveLotList} status={lotListTabNames[tab]} tab={tab} setSearchQuery={setSearchQuery} searchQuery={searchQuery} />
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <LotListTabs
          activeTab={tab}
          handleTabChange={handleTabChange}
          tabInfo={[
            { name: "Alle", color: tab === 0 ? "#ffffff" : "" },
            { name: "Aktiv", color: tab === 1 ? "#D9E868" : "" },
            { name: "Inaktiv", color: tab === 2 ? "#FF6A7D" : "" },
            { name: "Verkauft", color: tab === 3 ? "#CBCFD3" : "" },
            { name: "Geplant", color: tab === 4 ? "#83C0F8" : "" },
            // TODO: As long as we don't handle invoices, we don't need those
            // "Offene Rechnungen",
            // "Bezahlte Rechnungen",
          ]}
        />


        <BulkEditModal checkedBoxes={checkedBoxes} rowsPerPage={rowsPerPage} searchQuery={searchQuery} lotListTabNames={lotListTabNames} sort={sort} tab={tab} page={page} />

      </Box>
      <HolzfinderList
        listLengthTotal={lotListTotal}
        rowData={rowData}
        switchActive={true}
        link={`/${userRole}/losliste/`}
        tab={tab}
        switchStatus={switchStatus}
        tableHeaderList={userRole === "admin" ? tableHeaderListAdmin : tableHeaderListAgent}
        sortableHeaders={sortableHeaders}
        headersBackEndName={headersBackEndName}
        setPage={setPageLotList}
        setRowsPerPage={setRowsPerPageLotList}
        page={page}
        rowsPerPage={rowsPerPage === undefined ? 10 : rowsPerPage}
        isLotList={true}
        checkedBoxes={checkedBoxes}
        setCheckedBoxes={setCheckedBoxes}
      // handleSortChange={handleSortChange}
      />
    </Container>
  );
}

